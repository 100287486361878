<template>
 <v-dialog v-model="internalDialog" persistent width="550">
    <v-card class="pa-5">
      <div class="flex s-bt">
        <h3>설치위치(위도, 경도) 검색</h3>
        <v-icon @click="internalDialog=false">mdi-close</v-icon>
      </div>

      <div class="card_title">
        <div v-if="address"> 
          <v-icon color="black">mdi-home-map-marker</v-icon> 
          {{ address }}
        </div> 
        <div v-if="sensor_mac" style="color:gray">
          {{ sensor_mac }}
        </div>
      </div>

      <div class="map_info flex" >
        <div>
          <v-text-field dense outlined class="ma-1" style="height: 45px; width: auto;"
            id="lat" 
            ref="lat" 
            label="위도" 
            prepend-inner-icon="mdi-map-marker"
            v-model="internalLat" 
          />
        </div>
        <div>
          <v-text-field dense outlined class="ma-1" style="height: 45px; width: auto;"
            id="lng" 
            ref="lng" 
            label="경도"
            prepend-inner-icon="mdi-map-marker" 
            v-model="internalLng" 
          />
        </div>
      </div>

      <div id="map" style="width:100%; height:500px; margin-top: 10px;"></div>

      <div class="flex justify-center">
        <v-btn class="mt-3 normal_btn" @click="confirmLocation()">저장하기</v-btn>
      </div>
      
    </v-card>
  </v-dialog>
</template>

<script>
import Apis from '@/api/apis';

export default {
  name: 'LocationDialog',
  props: {
    value: Boolean,
    coordinates:Object,
    address:String,
    sensor_mac:String
  },
  created() {
    // this.loadKakaoMapAPI(); // API 로드 후 실행
    // this.initMap(); // 지도 초기화
  },
  data() {
    return {
      internalDialog: this.value,
      map: null,
      marker: null,

      internalLat: '',
      internalLng: '',

      mac:'',
    };
  },
  mounted() {
    if (this.value ) {
      this.loadKakaoMapAPI().then(() => {
        this.$nextTick(() => this.initMap());
      });
    }
  },
  watch: {
    value(val){
      this.internalDialog = val;

      if (val) {
        this.internalLat = this.coordinates.lat;
        this.internalLng = this.coordinates.lon;

        // 비동기로 지도 로딩
        this.loadKakaoMapAPI().then(() => {
          this.$nextTick(() => this.initMap());
        });
      }
    },
    internalDialog(val) {
      this.$emit('input', val); 
    }
  },
  methods: {
    confirmLocation() {
      this.$store.commit("resMessage","");
      this.mac = this.sensor_mac.replace(/:/gi, '');

      // console.log(this.mac)
      Apis.updateSensorFree({
        "sensor_mac": this.mac,
        "latitude": this.internalLat,
        "longitude": this.internalLng
        },(res) => {  
          if (res.result) {
            // console.log(res.data);
            this.$store.commit("resMessage",res.message);

            this.$emit('update:lat', this.internalLat);
            this.$emit('update:lng', this.internalLng);
            this.$emit('close');            

          } else {
            alert(res.message);
          }
        }).catch( (err) => {  // API 오류 처리
            console.log("updateSensorFree API 호출 오류",err)
            this.loadingSensor = false;
            // alert(err);
        }
      ); 
    },
    initMap() {
      if (!this.coordinates || !this.coordinates.lat || !this.coordinates.lon) {
        alert ('농장 좌표 정보가 없습니다. 다시 시도해주세요.');
        return;
      }

      const container = document.getElementById('map');
      const options = {
        center: new kakao.maps.LatLng(this.coordinates.lat, this.coordinates.lon),
        level: 1
      };

      this.map = new kakao.maps.Map(container, options);
      this.map.setMapTypeId(kakao.maps.MapTypeId.HYBRID);

      this.marker = new kakao.maps.Marker({
        position: new kakao.maps.LatLng(this.coordinates.lat, this.coordinates.lon),
        map: this.map
      });

      kakao.maps.event.addListener(this.map, 'click', (mouseEvent) => {
        const latlng = mouseEvent.latLng;
        this.marker.setPosition(latlng);
        this.internalLat = latlng.getLat();
        this.internalLng = latlng.getLng();
      });
    },
    loadKakaoMapAPI() {
      return new Promise((resolve, reject) => {
        if (window.kakao && kakao.maps && kakao.maps.services) {
          return resolve(); // 이미 로드된 경우
        }

        const script = document.createElement('script');
        script.src = 'https://dapi.kakao.com/v2/maps/sdk.js?autoload=false&appkey=84904cd1cc1af1bd9ff7067996ed7dd2&libraries=services';
        script.onload = () => {
          kakao.maps.load(() => resolve());
        };
        script.onerror = () => reject(new Error('Kakao Maps API 로드 실패'));
        document.head.appendChild(script);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../style/base.scss';
.card_title{
  text-align: center;
  font-weight:550;
  font-size:18px;
  letter-spacing: -1px;
  margin-top:20px !important;
}
.map_info{
  border:1px solid #e5e5e5;
  padding:10px;
  border-radius:15px; 
  justify-content: space-evenly;
}
.normal_btn{
  background-color:#3a4f3f !important;
  color:white !important;
  border-radius: 30px;
  height:45px !important;
  font-size:15px;
  letter-spacing: -1px;
  font-weight:600;
}
</style>